import React from 'react'
import { Layout, UserContext } from '../../components/Layout'
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid'
import { Heading } from '../../components/Heading'
import { HeadingLevel } from 'baseui/heading'
import { WindowLocation } from '@reach/router'
import { navigate } from 'gatsby'
import {
  getQueryVariable,
  formatDate,
  getIssueDateString,
  handleErrorFromAPI,
  loginRedirect
} from '../../utils'
import { getIssue, Issue, updateIssue } from '../../api/cms'
import { HorizontalLine } from '../../components/Line'
import { ParagraphLarge, LabelLarge } from 'baseui/typography'
import { useStyletron } from 'baseui'
import { ourColors } from '../../components/Colors'
import { Button } from '../../components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faCheck, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { NavButton } from '../../components/NavButton'
import { Modal, YesNoFooter } from '../../components/Modal'
import { PaddedAttribute } from '../../components/PaddedAttribute'
import { LinkButton } from '../../components/LinkButton'
import { ErrorNotification } from '../../components/Notification'
import constants from '../../constants'

interface IssueDetailProps {
  location: WindowLocation
}

interface ResolvedAskModalProps {
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
  token: string | null
  issue?: Issue
}

function toggleResolved(
  token: string | null,
  setIsLoading: (status: boolean) => void,
  setSubmitErrorActive: (status: boolean) => void,
  issue?: Issue
) {
  if (issue && issue.id && token) {
    setIsLoading(true)
    const data = {
      name: issue.name,
      category_id: issue.category_id,
      start_date: issue.start_date,
      resolved: !issue.resolved
    }
    updateIssue(issue.id, data, null, token)
      .then(() => {
        setIsLoading(false)
        navigate(`/issues/issue-list`)
      })
      .catch(() => {
        setIsLoading(false)
        setSubmitErrorActive(true)
      })
  }
}

const ResolvedAskModal = (props: ResolvedAskModalProps) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [submitErrorActive, setSubmitErrorActive] = React.useState(false)

  const footer = (
    <YesNoFooter
      yesCallback={() =>
        toggleResolved(props.token, setIsLoading, setSubmitErrorActive, props.issue)
      }
      setModalOpen={props.setModalOpen}
      yesText="Yes"
      noText="No"
      isLoading={isLoading}
      setIsLoading={setIsLoading}
    />
  )

  const submitErrorMessage = (
    <>
      A technical error occured while trying to move the issue to
      {props.issue && props.issue.resolved ? ' unresolved' : ' resolved'}.
      <br />
      If it persists, please contact {constants.supportEmail}.
    </>
  )

  return (
    <Modal
      headerText="Wait a minute"
      isOpen={props.modalOpen}
      footer={footer}
      setIsOpen={props.setModalOpen}
    >
      <div data-testid="confirmation-modal-body">
        Are you sure you want to move this issue to
        {props.issue && props.issue.resolved ? ' unresolved' : ' resolved'}?
      </div>
      <ErrorNotification hasError={submitErrorActive} errorMessage={submitErrorMessage} />
    </Modal>
  )
}

interface IssueSectionProps {
  setModalOpen: (value: boolean) => void
  issue?: Issue
}

const IssueSection = ({ setModalOpen, issue }: IssueSectionProps) => {
  const actionButtonOverrides = {
    BaseButton: { style: { marginTop: '16px', maxWidth: '345px', width: '100%' } }
  }
  const linkButtonStyles = {
    height: '50px',
    marginTop: '16px',
    maxWidth: '345px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
  const iconStyles = { paddingLeft: '12px', fontSize: '30px', paddingBottom: '9px' }
  const [css] = useStyletron()

  if (!issue) {
    return null
  } else {
    const recipients = issue.recipients ? issue.recipients.join(', ').split(' ') : []
    const recipients_list = recipients.map(r => (
      <ParagraphLarge overrides={{ Block: { style: { marginBottom: '0px' } } }} key={r}>
        {r}
      </ParagraphLarge>
    ))
    return (
      <Grid overrides={{ Grid: { style: { paddingBottom: '16px' } } }}>
        <Cell span={[4, 6]} align={ALIGNMENT.start}>
          <HeadingLevel>
            <div className="noAnalytics">
              <Heading overrides={{ Block: { style: { marginBottom: 0, marginTop: 0 } } }}>
                {issue.name}
              </Heading>
            </div>
            <ParagraphLarge overrides={{ Block: { style: { marginBottom: '10px' } } }}>
              {formatDate(issue.start_date)}
            </ParagraphLarge>
            <ParagraphLarge overrides={{ Block: { style: { marginBottom: '8px' } } }}>
              <div className="noAnalytics">{issue.notes}</div>
            </ParagraphLarge>
            <HorizontalLine className={css({ marginBottom: '16px' })} />
            <PaddedAttribute
              title={'Category'}
              value={issue.category_id ? (issue.category as string) : 'Other'}
            />
            <PaddedAttribute title={'Location'} value={issue.location || ''} />
            <PaddedAttribute title={'Date and Time'} value={getIssueDateString(issue)} />
            <LabelLarge
              overrides={{
                Block: {
                  style: {
                    marginBottom: '16px'
                  }
                }
              }}
            >
              Photograph
            </LabelLarge>
            <div>
              <img src={issue.photo} />
            </div>
            <LabelLarge
              overrides={{
                Block: {
                  style: {
                    marginBottom: '5px'
                  }
                }
              }}
            >
              This hazard has been emailed to
            </LabelLarge>
            <div className={css({ marginBottom: '14px' })}>{recipients_list}</div>
            <HorizontalLine className={css({ marginTop: '23px', marginBottom: '16px' })} />
            <HeadingLevel>
              <Heading
                overrides={{
                  Block: {
                    style: { color: ourColors.copyGrey, marginTop: '16px', marginBottom: '10px' }
                  }
                }}
              >
                Actions
              </Heading>
              <Cell span={[4, 6]}>
                <Button
                  kind="secondary"
                  data-testid="toggle-resolved-button"
                  overrides={actionButtonOverrides}
                  endEnhancer={() => <FontAwesomeIcon icon={faCheck} />}
                  onClick={() => setModalOpen(true)}
                >
                  Set as {issue.resolved ? 'unresolved' : 'resolved'}
                </Button>
                <LinkButton
                  data-testid="edit-issue-link"
                  className={css({
                    marginBottom: '0px',
                    ...linkButtonStyles
                  })}
                  to={`/issues/edit-issue?id=${issue.id}`}
                >
                  Update issue
                  <FontAwesomeIcon className={css(iconStyles)} icon={faPencilAlt} />
                </LinkButton>
                <LinkButton
                  data-testid="email-issue-link"
                  className={css(linkButtonStyles)}
                  to={`/issues/edit-issue?id=${issue.id}&focusSend=true`}
                >
                  Email this issue
                  <FontAwesomeIcon className={css(iconStyles)} icon={faEnvelope} />
                </LinkButton>
              </Cell>
            </HeadingLevel>
          </HeadingLevel>
        </Cell>
      </Grid>
    )
  }
}
export const IssueDetail = (props: IssueDetailProps) => {
  const issueId = getQueryVariable('id', props.location.search)
  const [issue, setIssue] = React.useState<Issue>()
  const [modalOpen, setModalOpen] = React.useState(false)
  const [loadingErrorActive, setLoadingErrorActive] = React.useState(false)
  const { token, setUserContext } = React.useContext(UserContext)

  React.useEffect(() => {
    if (!token) {
      loginRedirect()
    }
    if (!issueId) {
      navigate('/404', { replace: true })
    }

    if (token && issueId) {
      getIssue(token, issueId)
        .then((response: Issue) => {
          setIssue(response)
        })
        .catch(error => handleErrorFromAPI(error, setUserContext, setLoadingErrorActive))
    }
  }, [token, issueId, setUserContext])

  return (
    <HeadingLevel>
      <IssueDetailHeader
        issue={issue}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        token={token}
        loadingErrorActive={loadingErrorActive}
      />
      <IssueSection issue={issue} setModalOpen={setModalOpen} />
    </HeadingLevel>
  )
}

interface IssueDetailHeaderProps {
  issue: Issue | undefined
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
  token: string | null
  loadingErrorActive: boolean
}

const IssueDetailHeader = ({
  issue,
  modalOpen,
  setModalOpen,
  token,
  loadingErrorActive
}: IssueDetailHeaderProps) => {
  const [css] = useStyletron()

  return (
    <>
      <ResolvedAskModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        issue={issue}
        token={token}
      />
      <Grid align={ALIGNMENT.center}>
        <Cell span={[2.25, 3.5]}>
          <Heading>Issue Details</Heading>
        </Cell>
        {!loadingErrorActive && (
          <Cell span={1.5} skip={[0.25, 1]}>
            <div className={css({ display: 'flex', justifyContent: 'flex-end' })}>
              <NavButton
                buttonOverrides={{ BaseButton: { style: { marginTop: '0px', width: '122px' } } }}
                testId="actions-drop-down"
                navItems={[
                  {
                    label: 'Set as '.concat(issue && issue.resolved ? 'unresolved' : 'resolved'),
                    onClick: () => {
                      setModalOpen(!modalOpen)
                    },
                    testId: 'resolved-toggle-nav'
                  },
                  {
                    label: 'Edit Issue',
                    url: `/issues/edit-issue?id=${issue ? issue.id : undefined}`
                  },
                  {
                    label: 'Email this issue',
                    url: `/issues/edit-issue?id=${issue ? issue.id : undefined}&focusSend=true`
                  }
                ]}
              >
                Actions
              </NavButton>
            </div>
          </Cell>
        )}
        <Cell span={[4, 6]}>
          <HorizontalLine className={css({ marginBottom: '10px' })} />
        </Cell>
      </Grid>
      <Grid>
        <Cell span={[4, 6]}>
          <ErrorNotification hasError={loadingErrorActive} />
        </Cell>
      </Grid>
    </>
  )
}

interface LayoutIssueDetailProps {
  location: WindowLocation
}
const LayoutIssueDetail = (props: LayoutIssueDetailProps) => {
  return (
    <Layout>
      <IssueDetail location={props.location} />
    </Layout>
  )
}

export default LayoutIssueDetail
